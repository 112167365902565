<template>
  <v-footer

    height="auto"
    padless
    class="text-center"
  >
    <v-img
      v-if="!isMobile()"
      :src="image"
      gradient="to bottom, rgba(0,117,131,.5), rgba(0,117,131,.1)"
      height="100"
      position="bottom"
    >
      <div class="pa-10">
        <ul class="body-1 pl-0">
          <li
            v-for="(link,i) in links"
            :key="i"
            class="mb-3 d-inline mr-5"
          >
            <a
              class="transition-swing"
              :href="link.to"
              target="_blank"
            ><v-icon class="white--text">{{ link.icon }}</v-icon></a>
          </li>
        </ul>
      </div>
    </v-img>

    <v-row>
      <v-col
        style="background-color:  rgba(0,117,131,1) "
        class="text-center  pa-3 white--text"
        cols="12"
      >
        &copy; {{ moment(myDate).year() }} Oria Agriculture — {{ $t('all_rights_reserved') }}. |

        <span v-if="$i18n.locale=='fr'"><a
        class="text--accent-1"
        :href="'/' + $i18n.locale + '/legal'"
      >{{ $t('footer.legal') }}</a>  |</span> <a
        text
        small
        :href="'/' + $i18n.locale + '/sales-terms'"
      >{{ $t('footer.sales_terms') }}</a>
        |
        <a
          text
          small
          :href="'/' + $i18n.locale + '/privacy'"
        >{{ $t('footer.privacy') }}</a>
      </v-col>
    </v-row>
    <cookie-law
      theme="dark-lime"
      v-bind:buttonText='$t("footer.accept")'
      v-bind:message='$t("footer.cookie_msg")'
    />
  </v-footer>
</template>

<script>
  import moment from 'moment'
  import CookieLaw from 'vue-cookie-law'
  export default {
    components: {
      CookieLaw,
    },
    data: () => ({
      myDate: new Date(),
      links: [
        { icon: 'mdi-twitter', to: 'https://twitter.com/OriaAgriculture' },
        { icon: 'mdi-linkedin', to: 'https://www.linkedin.com/company/oriaagriculture' },
        { icon: 'mdi-youtube', to: 'https://www.youtube.com/channel/UCLBtMTPOW33WlvKlCyggjzQ' },
        { icon: 'mdi-facebook', to: 'https://www.facebook.com/oriaagriculture/' },
      ],
    }),

    computed: {

      image () {
        return '/static/' + (this.$route.meta && this.$route.meta.hero ? this.$route.meta.hero : this.$route.name) + '-hero.jpg'
      },
    },
    methods: {
      moment,
      isMobile () {
        if (screen.width <= 960) {
          return true
        } else {
          return false
        }
      },
    },
  }
</script>

<style lang="sass">
  .v-application .v-footer
    ul
      list-style-type: none

    a
      color: white
      text-decoration: none

      &:hover
        text-decoration: underline
</style>
